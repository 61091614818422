export const workFlow = [
  {
    flow_decider: {
      flow_checker: [
        [
          { field_name: "user_group", field_value: ["Project Team"] },
          {
            field_name: "significant_level_of_observation",
            field_value: ["Compliance", "No Activity"],
          },
          { decided_flow: "PM_Flow" },
        ],
        [
          {
            field_name: "user_group",
            field_value: ["Safety Oversight Team"],
          },
          {
            field_name: "significant_level_of_observation",
            field_value: ["Compliance", "No Activity"],
          },
          { decided_flow: "SO_Flow" },
        ],
      ],
      flow_eligible: {
        PM_Flow: {
          approve_eligible: ["Project Management Team Vetter"],
          permission: ["vetter_ohs", "vetter_ohs_limited"],
        },
        SO_Flow: {
          approve_eligible: ["Safely Oversight Team Vetter"],
          permission: ["vetter_ohs", "vetter_ohs_limited"],
        },
      },
    },
    stages: {
      stage_1: {
        editable: {
          editable_fields_without_permission: [
            "main_details",
            "sub_details",
            "panel-checklist",
          ],
          editable_fields: ["main_details", "sub_details", "panel-checklist"],
          role_checker: false,
          permission_checker: false,
        },
        status: ["draft"],
      },
      stage_2: {
        editable: {
          editable_fields: [
            "main_details",
            "sub_details",
            "panel-checklist",
            "panel-vetter",
          ],
          editable_fields_without_permission: [
            "main_details",
            "sub_details",
            "panel-checklist",
          ],
          editable_fields_without_permission_role: [
            "Safely Oversight Team Vetter",
            "Project Management Team Vetter",
          ],
          can_edit_record_detail: true,
          editable_role: [
            "Safely Oversight Team Vetter",
            "Project Management Team Vetter",
          ],
          editable_status: ["open"],
          render_status_for_approval_dropdown: [
            {
              id: "closed",
              text: "Closed",
              key_for_config: "approved",
            },
            // {
            //   id: "not_valid",
            //   text: "Not Valid",
            //   key_for_config: "rejected",
            // },
          ],
          stage_label: "Vetting",
          workflow_type: "Other",
          approval_key: "vetter_approval",
          rejected_stage: "stage_3",
          panel_id: "panel-vetter",
          editable_permission: ["vetter_ohs", "vetter_ohs_limited"],
          role_checker: true,
          permission_checker: true,
          approval_fields: ["approval_status", "remarks_for_rejection"],
        },
        status: ["open"],
      },
      stage_3: {
        editable: {
          editable_fields: [],
          editable_role: [""],
          editable_permission: [""],
          role_checker: true,
          permission_checker: true,
        },
        status: [
          "closed",
          // "not_valid"
        ],
      },
    },
  },
  {
    flow_decider: {
      flow_checker: [
        [
          { field_name: "user_group", field_value: ["Project Team"] },
          {
            field_name: "significant_level_of_observation",
            field_value: ["Observation", "Significant Observation"],
          },
          { decided_flow: "PM_Flow" },
        ],
      ],
      flow_eligible: {
        PM_Flow: {
          approve_eligible: [
            "Project Management Team Vetter",
            "Action Party",
            "Project Management Team Approver",
          ],
          permission: [
            "vetter_ohs",
            "vetter_ohs_limited",
            "rectifaction_ohs",
            "rectifaction_ohs_limited",
            "approval_ohs",
            "approval_ohs_limited",
          ],
        },
      },
    },
    stages: {
      stage_1: {
        editable: {
          editable_fields_without_permission: [
            "main_details",
            "sub_details",
            "panel-checklist",
          ],
          editable_fields: ["main_details", "sub_details", "panel-checklist"],
          role_checker: false,
          permission_checker: false,
        },
        status: ["draft"],
      },
      stage_2: {
        editable: {
          editable_fields: [
            "main_details",
            "sub_details",
            "panel-checklist",
            "panel-vetter",
          ],
          editable_fields_without_permission: [
            "main_details",
            "sub_details",
            "panel-checklist",
          ],
          can_edit_record_detail: true,
          editable_fields_without_permission_role: [
            "Safely Oversight Team Vetter",
            "Project Management Team Vetter",
          ],
          render_status_for_approval_dropdown: [
            {
              id: "for_rectification",
              text: "For Rectification",
              key_for_config: "approved",
            },
            {
              id: "not_valid",
              text: "Not Valid",
              key_for_config: "rejected",
            },
          ],
          stage_label: "Vetting",
          workflow_type: "3",
          approval_key: "vetter_approval",
          rejected_stage: "stage_3",
          approval_fields: ["approval_status", "remarks_for_rejection"],
          panel_id: "panel-vetter",
          editable_role: ["Project Management Team Vetter"],
          editable_permission: ["vetter_ohs", "vetter_ohs_limited"],
          editable_status: ["open"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["open"],
      },
      stage_3: {
        editable: {
          editable_fields: ["panel-rectification"],
          editable_role: ["Action Party"],
          render_status_for_approval_dropdown: [
            {
              id: "for_approval",
              text: "For Approval",
              key_for_config: "approved",
            },
          ],
          rejected_stage: "stage_3",
          approval_fields: [
            "corrective_action_taken",
            "preventive_action_taken",
            "rectification_picture",
            "rectification_supporting_document",
            "approval_status",
          ],
          stage_label: "Rectification",
          workflow_type: "3",
          approval_key: "rectification",
          panel_id: "panel-rectification",
          editable_status: ["for_rectification"],
          editable_permission: ["rectifaction_ohs", "rectifaction_ohs_limited"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["for_rectification", "not_valid"],
      },
      stage_4: {
        editable: {
          editable_fields: ["panel-approval"],
          render_status_for_approval_dropdown: [
            {
              id: "closed",
              text: "Approved",
              key_for_config: "approved",
            },
            {
              id: "for_rectification",
              text: "Rejected",
              key_for_config: "rejected",
            },
          ],
          approval_key: "project_team_approval",
          approval_fields: [
            "remarks_for_rejection",
            "evidence_of_rejection",
            "approval_status",
          ],
          stage_label: "PM Approval",
          workflow_type: "3",
          rejected_stage: "stage_3",
          panel_id: "panel-approval",
          editable_status: ["for_approval"],
          editable_role: ["Project Management Team Approver"],
          editable_permission: ["approval_ohs", "approval_ohs_limited"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["for_approval"],
      },
      stage_5: {
        editable: {
          editable_fields: [],
          editable_role: [""],
          editable_permission: [""],
          role_checker: true,
          permission_checker: true,
        },
        status: ["closed", "for_rectification"],
      },
    },
  },
  {
    flow_decider: {
      flow_checker: [
        [
          {
            field_name: "user_group",
            field_value: ["Safety Oversight Team"],
          },
          {
            field_name: "significant_level_of_observation",
            field_value: ["Observation", "Significant Observation"],
          },
          { decided_flow: "SO_Flow" },
        ],
      ],
      flow_eligible: {
        SO_Flow: {
          approve_eligible: [
            "Safely Oversight Team Vetter",
            "Action Party",
            "Project Management Team Approver",
            "Safely Oversight Team Approver",
          ],
          permission: [
            "vetter_ohs",
            "vetter_ohs_limited",
            "rectifaction_ohs",
            "rectifaction_ohs_limited",
            "approval_ohs",
            "approval_ohs_limited",
          ],
        },
      },
    },
    stages: {
      stage_1: {
        editable: {
          editable_fields_without_permission: [
            "main_details",
            "sub_details",
            "panel-checklist",
          ],
          editable_fields: ["main_details", "sub_details", "panel-checklist"],
          role_checker: false,
          permission_checker: false,
        },
        status: ["draft"],
      },
      stage_2: {
        editable: {
          editable_fields: [
            "main_details",
            "sub_details",
            "panel-checklist",
            "panel-vetter",
          ],
          editable_fields_without_permission: [
            "main_details",
            "sub_details",
            "panel-checklist",
          ],
          can_edit_record_detail: true,
          editable_fields_without_permission_role: [
            "Safely Oversight Team Vetter",
            "Project Management Team Vetter",
          ],
          render_status_for_approval_dropdown: [
            {
              id: "for_rectification",
              text: "For Rectification",
              key_for_config: "approved",
            },
            {
              id: "not_valid",
              text: "Not Valid",
              key_for_config: "rejected",
            },
          ],
          stage_label: "Vetting",
          workflow_type: "3A",
          approval_key: "vetter_approval",
          rejected_stage: "stage_3",
          approval_fields: ["approval_status", "remarks_for_rejection"],
          panel_id: "panel-vetter",
          editable_role: ["Safely Oversight Team Vetter"],
          editable_permission: ["vetter_ohs", "vetter_ohs_limited"],
          editable_status: ["open"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["open"],
      },
      stage_3: {
        editable: {
          editable_fields: ["panel-rectification"],
          render_status_for_approval_dropdown: [
            {
              id: "for_approval",
              text: "For Approval",
              key_for_config: "approved",
            },
          ],
          rejected_stage: "stage_3",
          approval_fields: [
            "corrective_action_taken",
            "preventive_action_taken",
            "rectification_picture",
            "rectification_supporting_document",
            "approval_status",
          ],
          stage_label: "Rectification",
          workflow_type: "3A",
          approval_key: "rectification",
          panel_id: "panel-rectification",
          editable_role: ["Action Party"],
          editable_status: ["for_rectification"],
          editable_permission: ["rectifaction_ohs", "rectifaction_ohs_limited"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["for_rectification", "not_valid"],
      },
      stage_4: {
        editable: {
          editable_fields: ["panel-approval"],
          render_status_for_approval_dropdown: [
            {
              id: "for_approval",
              text: "Approved",
              key_for_config: "approved",
            },
            {
              id: "for_rectification",
              text: "Rejected",
              key_for_config: "rejected",
            },
          ],
          approval_fields: [
            "remarks_for_rejection",
            "evidence_of_rejection",
            "approval_status",
          ],
          stage_label: "PM Approval",
          workflow_type: "3A",
          approval_key: "project_team_approval",
          rejected_stage: "stage_3",
          panel_id: "panel-approval",
          editable_status: ["for_approval"],
          editable_role: ["Project Management Team Approver"],
          editable_permission: ["approval_ohs", "approval_ohs_limited"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["for_approval"],
      },
      stage_5: {
        editable: {
          editable_fields: ["panel-approval"],
          render_status_for_approval_dropdown: [
            {
              id: "closed",
              text: "Approved",
              key_for_config: "approved",
            },
            {
              id: "for_rectification",
              text: "Rejected",
              key_for_config: "rejected",
            },
          ],
          approval_fields: [
            "remarks_for_rejection",
            "evidence_of_rejection",
            "approval_status",
          ],
          stage_label: "SO Approval",
          workflow_type: "3A",
          approval_key: "safety_oversight_team_approval",
          rejected_stage: "stage_3",
          panel_id: "panel-approval",
          editable_status: ["for_approval"],
          editable_role: ["Safely Oversight Team Approver"],
          editable_permission: ["approval_ohs", "approval_ohs_limited"],
          role_checker: true,
          permission_checker: true,
        },
        status: ["for_approval"],
      },
      stage_6: {
        editable: {
          editable_fields: [""],
          editable_role: [""],
          editable_permission: [""],
          role_checker: true,
          permission_checker: true,
        },
        status: ["closed"],
      },
    },
  },
];
