import { useNode, useEditor } from '@craftjs/core';
import React from 'react';
import ContentEditable from 'react-contenteditable';

import { TextSettings } from './TextSettings';

export const Text = ({
  fontSize,
  textAlign,
  fontWeight,
  fontStyle,
  fontFamily,
  color,
  shadow,
  text,
  margin,
  tagName = 'h2',
  required,
  file_accept
}) => {
  const {
    connectors: { connect },
    setProp,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div className="flex items-center">
      <ContentEditable
        innerRef={connect}
        html={text} // innerHTML of the editable div
        disabled={!enabled}
        onChange={(e) => {
          setProp((prop) => (prop.text = e.target.value), 500);
        }} // use true to disable editing
        tagName={tagName} // Use a custom HTML tag (uses a div by default)
        style={{
          width: 'auto',
          margin: `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px`,
          color: `rgba(${Object.values(color)})`,
          fontSize: `${fontSize}px`,
          textShadow: `0px 0px 2px rgba(0,0,0,${(shadow || 0) / 100})`,
          fontWeight,
          fontStyle,
          fontFamily,
          textAlign,
        }}
      />
      {required ? <span className="text-red">*</span> : null}
      {file_accept ? <span style={{
        width: "auto",
        marginLeft: "10px",
        color: "rgb(92, 90, 90)",
        fontSize: "12px",
        textShadow: "rgba(0, 0, 0, 0) 0px 0px 2px",
        fontWeight: 500,
        fontStyle: "normal",
        textAlign: "left"
      }} className='file-accept'>( {file_accept} )</span>: null}
    </div>
  );
};

Text.craft = {
  displayName: 'Text',
  props: {
    fontSize: '15',
    textAlign: 'left',
    fontWeight: '500',
    fontStyle: 'normal',
    color: { r: 92, g: 90, b: 90, a: 1 },
    margin: [0, 0, 0, 0],
    shadow: 0,
    text: 'Text',
  },
  related: {
    toolbar: TextSettings,
  },
};
