import React, { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
// icon
import TableFilter from "../../assets/images/Icon/unsorted.svg";
// css
import "./table.css";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "@progress/kendo-react-indicators";
import { TableColumnFilter } from "./tablecolumnFilter";
import Unhide from "../../assets/images/Icon/unhide.svg";

export const SelectTable = (props) => {
  const ref = useRef();
  const [data, setData] = useState(props.data ? props.data : []);
  const [filtered, setFiltered] = useState(null);
  // let sortingID = "";
  const [sortingID, setSortingID] = useState("");
  const [showFilter, setShowFilter] = useState("");
  const [popupDetail, setPopupDetail] = useState("");
  const [filteredItemTemp, setFilteredItemTemp] = useState(
    props.filteredData && props.filteredData.filteredItem
      ? props.filteredData.filteredItem
      : {}
  );
  const [tempFilteredData, setTempFilteredData] = useState(
    props.filteredData && props.filteredData.filteredData
      ? props.filteredData.filteredData
      : []
  );

  console.log(props.filteredData);

  console.log(tempFilteredData);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const changeTimeFormat = (value) => {
    return `${
      new Date(value).getHours() >= 10
        ? new Date(value).getHours()
        : "0" + new Date(value).getHours()
    }:${
      new Date(value).getMinutes() >= 10
        ? new Date(value).getMinutes()
        : "0" + new Date(value).getMinutes()
    }`;
  };

  const sortForTable = (ascending, value) => {
    return function (a, b) {
      let first = value.id.includes(".")
        ? a[value.id.split(".")[0]]
        : a[value.id];
      let second = value.id.includes(".")
        ? b[value.id.split(".")[0]]
        : b[value.id];
      // equal items sort equally
      if (first === second) {
        return 0;
      }

      // nulls sort after anything else
      if (first === null) {
        return 1;
      }
      if (second === null) {
        return -1;
      }
      if (value.id.includes(".")) {
        console.log(
          "this is working",
          a[value.id.split(".")[0]]?.[value.id.split(".")[1]]
        );
      }
      console.log(a[value.id]);
      first =
        value.id === "date_of_observation"
          ? new Date(a[value.id])
          : value.id === "time_of_observation"
          ? // ? changeTimeFormat(a[value.id])
            a[value.id]
          : // : value.id === "company_carrying"
          // ? a[value.id]?.company_name
          value.id.includes(".")
          ? a[value.id.split(".")[0]]?.[value.id.split(".")[1]]
          : a[value.id];
      second =
        value.id === "date_of_observation"
          ? new Date(b[value.id])
          : value.id === "time_of_observation"
          ? // ? changeTimeFormat(b[value.id])
            b[value.id]
          : // : value.id === "company_carrying"
          // ? b[value.id]?.company_name
          value.id.includes(".")
          ? b[value.id.split(".")[0]]?.[value.id.split(".")[1]]
          : b[value.id];
      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
        return first < second ? -1 : 1;
      }

      // if descending, highest sorts first
      return first < second ? 1 : -1;
    };
  };

  const handleFilter = (value) => {
    const sorting =
      sortingID.id === value.id
        ? data.sort(sortForTable(!sortingID.isState, value))
        : data.sort(sortForTable(true, value));
    // if (sortingID.id === value.id) {
    //   setSortingID({
    //     id: value.id,
    //     isState: !sortingID.isState,
    //   });
    // } else {
    //   setSortingID({
    //     id: value.id,
    //     isState: true,
    //   });
    // }
    // const sorting = data.sort((a, b) => (a[value.id] > b[value.id] ? 1 : -1));
    setData(() => [...sorting]);
  };

  const closeOpenMenus = (e) => {
    if (!ref.current?.contains(e.target)) {
      setShowFilter("");
    }
  };

  const onClickFilter = (data) => {
    document.addEventListener("click", closeOpenMenus);
    if (showFilter === data.id) {
      setShowFilter("");
    } else {
      setShowFilter(data.id);
    }
  };

  const sortAtoZ = (ascending, value) => {
    setShowFilter("");
    const sorting = data.sort(sortForTable(ascending, value));
    setData(() => [...sorting]);
  };

  const onHandleFilterData = (value, data) => {
    let temp = {};
    if (filteredItemTemp[data.id] === undefined) {
      temp = {
        ...filteredItemTemp,
        [data.id]: [value],
      };
      tempFilteredData.push({
        id: data.id,
      });
      // setFilteredItemTemp([...tempFilteredData]);
      setFilteredItemTemp(temp);
    } else {
      if (!filteredItemTemp?.[data.id].find((i) => i === value)) {
        filteredItemTemp?.[data.id].push(value);
        setFilteredItemTemp({ ...filteredItemTemp });
      } else {
        const filtered = filteredItemTemp?.[data.id].filter((i) => i !== value);
        if (filtered.length > 0) {
          setFilteredItemTemp({ ...filteredItemTemp, [data.id]: filtered });
        } else {
          delete filteredItemTemp[data.id];
          const tempDeleted = tempFilteredData.filter((i) => i.id !== data.id);
          setFilteredItemTemp({ ...filteredItemTemp });
          setTempFilteredData(tempDeleted);
        }
      }
    }
  };

  const clearFilter = (value) => {
    // setShowFilter("");
    delete filteredItemTemp[value];
    const tempDeleted = tempFilteredData.filter((i) => i.id !== value);
    setFilteredItemTemp({ ...filteredItemTemp });
    localStorage.setItem(
      "OHSFILTER",
      JSON.stringify({
        filteredItem: filteredItemTemp,
        filteredData: tempDeleted,
      })
    );
    setTempFilteredData(tempDeleted);
    const filtered = props.data.filter((items) => {
      let conditions = tempDeleted.map((i) =>
        isDataSelected(items, filteredItemTemp[i.id], i.id)
      );
      if (conditions.find((j) => j === false) !== false) return true;
      else return false;
    });
    setData(filtered);
  };

  const isDataSelected = (item, selectedItems, itemKey) => {
    console.log(item, selectedItems, itemKey);
    const searchItem = itemKey.includes(".")
      ? item[itemKey.split(".")[0]] &&
        item[itemKey.split(".")[0]]?.[itemKey.split(".")[1]]
      : item[itemKey];
    const includesCheck = Array.isArray(searchItem)
      ? selectedItems.some((value) => searchItem.includes(value))
      : selectedItems.includes(searchItem);
    return !selectedItems.length || includesCheck;
  };

  const FilterApply = () => {
    // localStorage.setItem("OHSFILTER", JSON.stringify(filteredItemTemp));
    localStorage.setItem(
      "OHSFILTER",
      JSON.stringify({
        filteredItem: filteredItemTemp,
        filteredData: tempFilteredData,
      })
    );
    setShowFilter("");
    const filtered = props.data.filter((items) => {
      let conditions = tempFilteredData.map((i) =>
        isDataSelected(items, filteredItemTemp[i.id], i.id)
      );
      if (conditions.find((j) => j === false) !== false) return true;
      else return false;
    });
    setData(filtered);
  };

  const colorCheck = (data) => {
    if (data === "Aerodrome") {
      return "#c0e1f6";
    } else if (data === "Environmental") {
      return "#d4edbb";
    } else if (data === "Compliance") {
      return "#d4edbb";
    } else if (data === "Observation") {
      return "#ffe59f";
    } else if (data === "Significant Observation") {
      return "#b10202";
    } else if (data === "No Activity") {
      return "#e6e6e6";
    } else if (data === "draft") {
      return "grey";
    } else if (data === "open") {
      // return "#fff3cc";
      return "#FFFF00";
    } else if (data === "for_approval") {
      return "#FFFF00";
    } else if (data === "for_rectification") {
      return "#e06666";
    } else if (data === "closed") {
      return "#b7d7a8";
    } else if (data === "not_valid") {
      return "#94c37d";
    } else if (data === "Airside") {
      return "#a0c5e8";
    } else if (data === "Landside (Contractor's worksite and premises)") {
      return "rgb(148 195 125)";
    } else {
      return "rgb(204 204 204)";
    }
  };

  const getDataValue = (_data, dat) => {
    if (dat.id.includes(".")) {
      const split = dat.id.split(".");
      if (_data[`${split[0]}`] === null) {
        return "-";
      } else if (_data[`${split[0]}`]?.[`${split[1]}`] === undefined) {
        return "-";
      } else {
        return Array.isArray(_data[`${split[0]}`]?.[`${split[1]}`])
          ? _data[`${split[0]}`]?.[`${split[1]}`].join(", ")
          : _data[`${split[0]}`]?.[`${split[1]}`];
      }
    } else if (
      _data[`${dat.id}`] === null ||
      _data[`${dat.id}`] === undefined
    ) {
      return "-";
    } else if (typeof _data[`${dat.id}`] === "object") {
      if (_data[`${dat.id}`]?.[`${dat.objectValue}`] === undefined) {
        return "-";
      } else {
        return _data[`${dat.id}`]?.[`${dat.objectValue}`];
      }
    } else if (dat.objectValue !== undefined) {
      return _data[`${dat.id}`]?.[dat.objectValue];
    } else if (dat.format) {
      if (dat.format === "date") {
        return moment(_data[`${dat.id}`]).format("YYYY-MM-DD");
      }
      if (
        dat.format === "time" &&
        isNaN(new Date(_data[`${dat.id}`]).getHours())
      ) {
        return _data[`${dat.id}`];
      } else {
        return `${new Date(_data[`${dat.id}`]).getHours()}:${
          new Date(_data[`${dat.id}`]).getMinutes() >= 10
            ? new Date(_data[`${dat.id}`]).getMinutes()
            : "0" + new Date(_data[`${dat.id}`]).getMinutes()
        }`;
      }
    } else {
      return _data[`${dat.id}`];
    }
  };

  useEffect(() => {
    if (tempFilteredData.length > 0) {
      FilterApply();
    }
  }, [props.data]);

  const checkBoxCheck = () => {
    let isValue = true;
    props.exportIDS.length > 0
      ? props.data.map((j) => {
          if (!props.exportIDS.find((i) => i === j.id)) {
            return (isValue = false);
          }
        })
      : (isValue = false);
    return isValue;
  };

  return (
    <InfiniteScroll
      dataLength={data ? data.length : 0}
      // height={"calc(100vh - 146px)"}
      height={props.maxHeight}
      style={{ width: "calc(100vw - 100px)" }}
      loader={
        <Loader
          style={{
            position: "relative",
            height: "70px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
          }}
        />
      }
      next={() => props.handlePageChange(props.activePages)}
      hasMore={props.hasMore ? props.hasMore : false}
    >
      {/* <div
        style={{
          overflowY: "hidden",
          maxHeight:
            props.maxHeight > 0 && props.cutHeaderHeight == true
              ? "calc(100vh - " + props.maxHeight + ")"
              : props.maxHeight
              ? props.maxHeight
              : "calc(100vh - 320px)",
          overflowY: "auto",
          width: props.width ? props.width : "initial",
          maxWidth: props.maxWidth ? props.maxWidth : "calc(100vw - 260px)",
          overflowX: "auto",
          height: props.height ? props.height : "",
        }}
        className="table-select-custom"
        id="scrollableDivHistory"
      > */}
      {props.header && (
        <table ref={ref}>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "#EFEFEF",
              zIndex: 999,
            }}
          >
            <tr style={{ height: "50px" }}>
              <th className="table_sticky_column_title">Action</th>
              <th style={{ height: "50px", width: "40px" }}>
                <Checkbox
                  checked={
                    checkBoxCheck()
                    // props.exportIDS.length >=
                    // props.data.length * props.activePages
                    //   ? true
                    //   : false
                  }
                  defaultChecked={data.headerCheckboxFlag}
                  onClick={props.headerCheckboxClicked}
                />
              </th>
              {props.header &&
                props.header.map((data, id) => {
                  if (data.isShow === false) return null;
                  else
                    return (
                      <th key={id} style={{ cursor: "pointer" }}>
                        {showFilter === data.id && (
                          <TableColumnFilter
                            totalCount={props.header && props.header.length}
                            columnId={id}
                            columnWidth={data.width}
                            columnName={data.id}
                            columnFilterData={props.columnFilterData?.[data.id]}
                            setShowFilter={setShowFilter}
                            data={data}
                            sortAtoZ={sortAtoZ}
                            onHandleFilterData={onHandleFilterData}
                            filteredItemTemp={filteredItemTemp}
                            clearFilter={clearFilter}
                            FilterApply={FilterApply}
                          />
                        )}
                        <div
                          className="Table_header"
                          style={{
                            width: data.width ? data.width : "100px",
                            padding: !props.headerPadding && "0px",
                          }}
                        >
                          {data.name}
                          {
                            data.filter !== undefined ? (
                              data.filter === true ? (
                                // <img src={TableFilter} />
                                <span
                                  style={{
                                    // color: showFilter === data.id && "#EB7930",
                                    color:
                                      tempFilteredData.find(
                                        (i) => i.id === data.id
                                      ) && "#EB7930",
                                  }}
                                  class="k-icon k-font-icon k-i-filter"
                                  onClick={() => {
                                    if (
                                      data.filter !== undefined &&
                                      data.filter === true
                                    ) {
                                      // handleFilter(data);
                                      onClickFilter(data);
                                    }
                                  }}
                                ></span>
                              ) : (
                                ""
                              )
                            ) : null
                            // <img src={TableFilter} />
                            // <span class="k-icon k-font-icon k-i-filter"></span>
                          }
                        </div>
                      </th>
                    );
                })}
            </tr>
          </thead>
          <tbody>
            {props.pageChangeLoading && props.pageChangeLoading ? (
              <Loader
                type="infinite-spinner"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "calc(100% - 100px)",
                  position: "absolute",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  height: "calc(100vh - 252px)",
                }}
              />
            ) : data.length > 0 ? (
              data.map((_data, id) => (
                <tr
                  className="TableHandle"
                  key={Math.floor(Math.random() * 10000000)}
                >
                  <td className="table_sticky_column_detail">
                    <div style={{ position: "relative" }}>
                      <div
                        onClick={() =>
                          props.handleTableClick &&
                          props.handleTableClick(_data.id)
                        }
                        onMouseEnter={() => setPopupDetail(_data.id)}
                        onMouseLeave={() => setPopupDetail("")}
                        className="eye_icon_table"
                        style={{
                          backgroundImage: `url(${Unhide})`,
                          cursor: "pointer",
                        }}
                      />
                      {popupDetail === _data.id && (
                        <div className="table_tool_tips_main_box">
                          <div className="table_tool_tips_text_box">
                            View Detail
                          </div>
                          <div className="table_tool_tips"></div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <Checkbox
                      defaultChecked={_data.selected}
                      id={`${_data.id ? _data.id : ""}`}
                      checked={
                        props.exportIDS
                          ? props.exportIDS.find((i) => i === _data.id)
                            ? true
                            : false
                          : false
                      }
                      // data-id={`${_data.id}`}
                      onClick={props.rowCheckboxClicked}
                    />
                  </td>
                  {props.header &&
                    props.header.map((dat, i) => {
                      if (dat.isShow === false) return null;
                      else {
                        if (props.button) {
                          if (props.header.length - 1 > i)
                            return (
                              <td key={dat.id}>
                                {(dat.id === "work_zone" ||
                                  dat.id ===
                                    "significant_level_of_observation" ||
                                  dat.id === "type_of_risk" ||
                                  dat.id === "status") && (
                                  <span
                                    style={{
                                      marginRight: "15px",
                                      fontSize: "20px",
                                      display:
                                        _data[`${dat.id}`] === null && "none",
                                      color: colorCheck(_data[`${dat.id}`]),
                                      // _data[`${dat.id}`] === "Aerodrome"
                                      //   ? "#c0e1f6"
                                      //   : _data[`${dat.id}`] ===
                                      //     "Environmental"
                                      //   ? "#d4edbb"
                                      //   : _data[`${dat.id}`] ===
                                      //     "Environmental"
                                      //   ? "#ffe59f"
                                      //   : _data[`${dat.id}`] ===
                                      //     "Compliance"
                                      //   ? "#d4edbb"
                                      //   : _data[`${dat.id}`] ===
                                      //     "Observation"
                                      //   ? "#ffe59f"
                                      //   : _data[`${dat.id}`] ===
                                      //     "Significant Observation"
                                      //   ? "#b10202"
                                      //   : _data[`${dat.id}`] ===
                                      //     "No Activity"
                                      //   ? "#e6e6e6"
                                      //   : _data[`${dat.id}`] === "draft"
                                      //   ? "#ffffff"
                                      //   : _data[`${dat.id}`] === "open"
                                      //   ? "#fff3cc"
                                      //   : _data[`${dat.id}`] ===
                                      //     "for_approval"
                                      //   ? "#fff3cc"
                                      //   : _data[`${dat.id}`] ===
                                      //     "for_rectification"
                                      //   ? "#e06666"
                                      //   : _data[`${dat.id}`] === "closed"
                                      //   ? "#b7d7a8"
                                      //   : _data[`${dat.id}`] === "not_valid"
                                      //   ? "#94c37d"
                                      //   : _data[`${dat.id}`] === "Airside"
                                      //   ? "#a0c5e8"
                                      //   : _data[`${dat.id}`] ===
                                      //     "Landside (Contractor's worksite and premises)"
                                      //   ? "rgb(148 195 125)"
                                      //   : "rgb(204 204 204)",
                                    }}
                                  >
                                    ●
                                  </span>
                                )}
                                {getDataValue(_data, dat)}

                                {/* {_data[`${dat.id}`] === null
                                    ? "-"
                                    : typeof _data[`${dat.id}`] === "object"
                                    ? _data[`${dat.id}`].company_name
                                    : dat.objectValue !== undefined
                                    ? _data[`${dat.id}`]?.[dat.objectValue]
                                    : dat.format
                                    ? dat.format === "date"
                                      ? moment(_data[`${dat.id}`]).format(
                                          "YYYY-MM-DD"
                                        )
                                      : dat.format === "time" &&
                                        isNaN(
                                          new Date(
                                            _data[`${dat.id}`]
                                          ).getHours()
                                        )
                                      ? _data[`${dat.id}`]
                                      : `${new Date(
                                          _data[`${dat.id}`]
                                        ).getHours()}:${
                                          new Date(
                                            _data[`${dat.id}`]
                                          ).getMinutes() >= 10
                                            ? new Date(
                                                _data[`${dat.id}`]
                                              ).getMinutes()
                                            : "0" +
                                              new Date(
                                                _data[`${dat.id}`]
                                              ).getMinutes()
                                        }`
                                    : _data[`${dat.id}`]} */}
                              </td>
                            );
                        } else {
                          return (
                            <td key={dat.id}>
                              {(dat.id === "work_zone" ||
                                dat.id === "significant_level_of_observation" ||
                                dat.id === "type_of_risk" ||
                                dat.id === "status") && (
                                <span
                                  style={{
                                    marginRight: "15px",
                                    fontSize: "20px",
                                    display:
                                      _data[`${dat.id}`] === null && "none",
                                    color: colorCheck(_data[`${dat.id}`]),
                                    // _data[`${dat.id}`] === "Aerodrome"
                                    //   ? "#c0e1f6"
                                    //   : _data[`${dat.id}`] ===
                                    //     "Environmental"
                                    //   ? "#d4edbb"
                                    //   : _data[`${dat.id}`] ===
                                    //     "Environmental"
                                    //   ? "#ffe59f"
                                    //   : _data[`${dat.id}`] === "Compliance"
                                    //   ? "#d4edbb"
                                    //   : _data[`${dat.id}`] === "Observation"
                                    //   ? "#ffe59f"
                                    //   : _data[`${dat.id}`] ===
                                    //     "Significant Observation"
                                    //   ? "#b10202"
                                    //   : _data[`${dat.id}`] === "No Activity"
                                    //   ? "#e6e6e6"
                                    //   : _data[`${dat.id}`] === "Airside"
                                    //   ? "#a0c5e8"
                                    //   : _data[`${dat.id}`] ===
                                    //     "Landside (Contractor's worksite and premises)"
                                    //   ? "rgb(148 195 125)"
                                    //   : "rgb(204 204 204)",
                                  }}
                                >
                                  ●
                                </span>
                              )}
                              {/* {_data[`${dat.id}`] === null
                                  ? "-"
                                  : typeof _data[`${dat.id}`] === "object"
                                  ? _data[`${dat.id}`].company_name
                                  : dat.objectValue !== undefined
                                  ? _data[`${dat.id}`]?.[dat.objectValue]
                                  : dat.format
                                  ? dat.format === "date"
                                    ? moment(_data[`${dat.id}`]).format(
                                        "YYYY-MM-DD"
                                      )
                                    : isNaN(
                                        new Date(_data[`${dat.id}`]).getHours()
                                      )
                                    ? _data[`${dat.id}`]
                                    : `${new Date(
                                        _data[`${dat.id}`]
                                      ).getHours()}:${
                                        new Date(
                                          _data[`${dat.id}`]
                                        ).getMinutes() >= 10
                                          ? new Date(
                                              _data[`${dat.id}`]
                                            ).getMinutes()
                                          : "0" +
                                            new Date(
                                              _data[`${dat.id}`]
                                            ).getMinutes()
                                      }`
                                  : _data[`${dat.id}`]} */}
                              {getDataValue(_data, dat)}
                            </td>
                          );
                        }
                      }
                    })}
                  {props.button && (
                    <td style={{ minWidth: "150px", maxWidth: "155px" }}>
                      <div
                        className="Table_button"
                        onClick={() => {
                          if (props.buttonClick) props.buttonClick(_data.id);
                        }}
                      >
                        {props.button}
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              ""
            )}
          </tbody>
        </table>
      )}
      {/* </div> */}
    </InfiniteScroll>
  );
};
